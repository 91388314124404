:host-context(.mobile) ::ng-deep {
  .kyc-mobile-hide {
    display: none !important;
  }

  .kyc-mobile-show {
    display: inherit;
  }
}


::ng-deep .kyc-mobile-show {
  display: none;
}

.kyc-mobile-ui-back {
  display: block;
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.kyc-test-preferences-dark {
  display: none;

  @media (prefers-color-scheme: dark) {
    display: block;
  }

}

.kyc-test-preferences-light {
  display: none;

  @media (prefers-color-scheme: light) {
    display: block;
  }
}
